export const INTRO_HOME = `
<div class="reward-content">
  <div class="reward-part">
    <p style="text-align:left">
    Duyên dáng sinh viên Việt Nam là cuộc thi tìm kiếm gương mặt đại diện cho thế hệ nữ sinh tài sắc và trí tuệ được tổ chức dưới sự chỉ đạo của Công ty Cổ phần Truyền thông Nhị Vân.
    </p>
    <p style="text-align:left">
    Duyên dáng sinh viên Việt Nam - The Charming Beauty mang đến nhiều sự mới mẻ, thu hút cả về hình thức lẫn nội dung. Với chủ đề “𝐏𝐔𝐑𝐄 𝐆𝐋𝐎𝐖”, Ban Tổ chức luôn trân trọng sự khác biệt và tin rằng mọi nữ sinh đều mang những vẻ đẹp, giá trị riêng biệt. Đồng thời tạo ra sân chơi để thúc đẩy tinh thần học tập và rèn luyện trong tương lai nhằm xây dựng khối đoàn kết tương thân tương ái, gắn bó cộng đồng giữa các bạn sinh viên trên mọi miền Tổ quốc.
    </p>
    </div>
  </div>
</div>
`;

export const INTRO_TITLE = `Là cổng bình chọn chính thức của cuộc thi Duyên dáng sinh viên Việt Nam, Bvote tự hào mang đến giải pháp bình chọn trực tuyến công bằng, minh bạch cho các thí sinh.`;

export const INTRO = `
  <div class="reward-content">
  <div class="reward-part">
<div class="item">
<p>
<span style="font-size:16px; font-family: roboto; ">
<span style="color:#000000">
<span style="font-family:roboto">
Cổng bình chọn Bvote sẽ đồng hành cùng cuộc thi để tìm kiếm ra những thí sinh được yêu thích nhất qua sự bình chọn của khán giả. 
  </span>
  </span>
  </span>
  </p>
   <p>
  <span style="font-size:16px"><span style="color:#000000">
  <span style="font-family:roboto;line-height: 150%;">
  Việc hoạt động dựa trên công nghệ chuỗi khối của Blockchain giúp giải pháp bình chọn của Bvote sở hữu những ưu điểm vượt trội về tính an toàn, bảo mật, đem lại kết quả công khai và minh bạch nhất. Thay vì sử dụng phương thức truyền thống bằng tin nhắn, khán giả sẽ ủng hộ cho thí sinh mình yêu thích nhất thông qua hệ thống bình chọn trên Website. Giải pháp này cho phép mọi người theo dõi kết quả liên tục, trực tiếp trên hệ thống, một điều mà các hình thức cũ không làm được. Không chỉ loại bỏ nguy cơ gian lận, Bvote còn mang đến trải nghiệm bình chọn mới lạ, tiện lợi và dễ sử dụng. Nhất là khi giải pháp bình chọn của Bvote còn kết hợp cùng các ví điện tử uy tín để đảm bảo mức độ bảo mật cao và giảm thiểu tối đa tình trạng hack vote.
  <br/>
  <br/>
  </span>
    </span>
      </p> 
      <p>
      &nbsp;
      </p> 
      <p>
      <span style="font-size:16px">
      <span style="color:#000000">
      <span style="font-family:roboto">
      <strong class="item-title">
      Cách thức bình chọn:
      </strong>
      <br/>
      </span>
      </span>
      <span style="color:#000000">
      <span style="font-family:roboto"> 
      </span>
      </span>
      </span>
      </p>
      <div class="item-method">
      <div class="item-step">
       <p>
       <span style="font-size:16px">
       <span style="color:#2B2438">
       <span class="item-text" style="font-family:roboto;line-height: 150%;">
       <i>Bước 1: &nbsp;</i> Truy cập website: https://duyendangsinhvienvietnam.bvote.vn/. Tại phần danh sách thí sinh, click chi tiết vào thí sinh muốn bình chọn
       </span>
       </span>
       </span>
       </p> 
       </div>
       <div class="item-step">
       <p>
       <span style="font-size:16px">
       <span style="color:#2B2438">
       <span class="item-text" style="font-family:roboto;line-height: 150%;">
       <i>Bước 2: &nbsp;</i> Chọn cổng thanh toán. Hệ thống sẽ trả về link để bình chọn thí sinh
       </span>
       </span>
       </span>
       </p>
       </div>
       <div class="item-step">
        <p><span style="font-size:16px">
        <span style="color:#2B2438">
        <span class="item-text" style="font-family:roboto;line-height: 150%;">
        <i>Bước 3: &nbsp;</i> Truy cập link thanh toán, tiến hành thanh toán (tương đương bình chọn). 

        </span>
        </span>
        </span>
        </p>
        </div>
        <div class="item-step">
         <p>
         <span style="font-size:16px">
         <span style="color:#2B2438">
         <span class="item-text" style="font-family:roboto;line-height: 150%;">
         <i>Bước 4: &nbsp;</i> Hệ thống check thanh toán thành công, hệ thống Bvote sẽ tiến hành lưu trữ kết quả bình chọn. Phí bình chọn theo gói lựa chọn và 1 lượt bình chọn tương đương với số điểm theo gói bình chọn mà khán giả đã chọn. Lưu ý không giới hạn số lượt bình chọn và số thí sinh bình chọn.
         </span>
         </span>
         </span>
         </p> 
         </div>
         </div>
         <br/>
          <p>
          &nbsp;
          </p> 
          <p>
          <span style="font-size:16px">
          <strong>
          <span>
          <span style="font-family:roboto" class="item-structure">
          Cơ cấu giải thưởng: 
          </span>
          </span>
          </strong>
          </span>
          </p> 
          <p class="structure">
          <span style="font-size:16px">
          <span style="color:#2B2438">
          <span style="font-family:roboto">
          *** Cổng bình chọn của Bvote sẽ chính thức được mở vào 20h00 ngày 06/02/2024 và sẽ kết thúc vào 20h00 ngày 20/02/2024 để chọn ra thí sinh có điểm cao nhất.
          </p> 
          <br/>
           <p class="structure">
          <span style="font-size:16px">
          <span style="color:#2B2438">
          <span style="font-family:roboto">
          *** Thí sinh có số điểm cao nhất sẽ được Đặc cách vào thẳng Đêm Chung Kết của cuộc thi Duyên dáng sinh viên Việt Nam, tiến 1 bước gần hơn tới ngôi vị danh giá nhất của cuộc thi.
          </p> 
</div>
</div>
</div>
  `;
