import { useCallback, useState } from "react";
import { request } from "./api/axios";
import API from "./api/url.js";
import { IMAGE_HOST } from "../config/constants";

export const useActivity = () => {
  const [data, setData] = useState([]);
  const getData = useCallback(async () => {
    const res = await request({
      method: "GET",
      url: API.ACTIVITY,
    });
    setData(res?.data?.voteList || []);
  }, []);
  return { data, getData };
};

export const useContestant = () => {
  const [contestants, setContestant] = useState([]);
  const [ranks, setRanks] = useState([]);

  const getContestant = useCallback(async () => {
    const res = await request({
      method: "GET",
      url: API.CONTESTANT,
    });
    setContestant(res?.data?.listAll || []);
  }, []);

  const getRanks = useCallback(async () => {
    const res = await request({
      method: "GET",
      url: API.RANK,
    });
    setRanks(res?.data?.rank || []);
  }, []);

  return {
    contestants,
    ranks,
    getRanks,
    getContestant,
  };
};

export const useVoteList = () => {
  const [voteList, setVoteList] = useState([]);
  const [isHasMore, setHasMore] = useState(true);
  const getVoteList = useCallback(
    async (missId, pageNumber, pageSize) => {
      if (isHasMore) {
        const res = await request({
          method: "GET",
          url: API.VOTE_LIST(missId, pageNumber, pageSize),
        });
        const temp = res?.data?.missVoteList || [];
        if (temp.length === 0) setHasMore(false);
        setVoteList((prev) => prev.concat(temp));
      }
    },
    [isHasMore]
  );
  return { voteList, isHasMore, getVoteList };
};

export const useContestantDetail = () => {
  const [contestant, setContestant] = useState({});
  const [time, setTime] = useState({});
  const getContestantDetail = useCallback(async (missId) => {
    const res = await request({
      method: "GET",
      url: API.CONTESTANT_DETAIL(missId),
    });
    setContestant(res?.data?.missDetail || {});
    setTime(res.data?.time || {});
  }, []);

  const getPayUrl = useCallback(async (gateway, missId, combo) => {
    const res = await request({
      method: "GET",
      url: API.PAY_URL(gateway, missId, combo),
    });
    return res;
  }, []);

  return { time, contestant, getContestantDetail, getPayUrl };
};

export const configId = (missId) => {
  let configId = missId;
  if (missId === "1") return missId;
  for (let j = 1; j < 2; j++) {
    if (configId && configId.length < 2) {
      configId = "0" + configId;
    }
  }
  return configId;
};

export const getAvatarUrl = (missId, table) => {
  return `${IMAGE_HOST}/ddsv2024/Avatar/${missId}.png`;
};

export const getDetailImage = (missId, table) => {
  return `${IMAGE_HOST}/ddsv2024/Detail/${missId}.png`;
};
