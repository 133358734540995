import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { INTRO_HOME } from "../../../config/contentHtml";
import { IoMdMail } from "react-icons/io";
import { FaPhone } from "react-icons/fa";
import { MdLink } from "react-icons/md";
import styled from "styled-components";
// import { ReactComponent as IconLink } from "/images/icons/icon_link.png";
class SectionAbout extends Component {
  state = {
    intro: "",
  };

  async componentDidMount() {
    try {
      const INTRO = this.props.lang === "en" ? INTRO_HOME : INTRO_HOME;

      const intro = INTRO;

      this.setState({ intro });
    } catch (error) {
      console.log("xxx Get intro : ", error);
    }
  }

  // static getDerivedStateFromProps(props, state) {
  //   return { lang: props.lang };
  // }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.lang !== this.props.lang) {
      const INTRO = this.props.lang === "en" ? INTRO_HOME : INTRO_HOME;

      // const INTRO = this.props.lang === 'en' ? INTRO5_EN : INTRO5;

      const intro = INTRO;

      this.setState({ intro });
    }
  }

  render() {
    const { intro } = this.state;

    return (
      // <section className="section-about">
      //   <div className="about-img">
      //     <img src="images/about_img.jpg" alt="" data-aos="zoom-out-left" />
      //   </div>
      //   <div className="bs-container">
      //     <div className="bs-row row-md-15">
      //       <div className="bs-col md-50-15">
      //         <div className="module module-about" data-aos="zoom-out-right">
      //           <div className="module-header">
      //             <h2 className="title">
      //               <FormattedMessage id="nav.intro" />
      //             </h2>
      //           </div>
      //           <div className="module-content">
      //             <div className="desc" style={{ marginBottom: 5 }}>
      //               {ReactHtmlParser(intro)}
      //             </div>
      //             <Link className="about__link" to="/gioi-thieu">
      //               <FormattedMessage id="index.continue" />
      //             </Link>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </section>

      <Styles className="section-about">
        <div className="bs-container">
          <div className="item-row">
            <div className="module module-about" data-aos="zoom-out-right">
              <div className="module-header">
                {/* <span className="id">01</span> */}
                <h2 className="title">Giới thiệu cuộc thi</h2>
              </div>
              <div className="module-content">
                <div className="desc" style={{ marginBottom: 5 }}>
                  {ReactHtmlParser(intro)}
                </div>

                <ul className="info-website">
                  <p className="title">
                    CÔNG TY CỔ PHẦN{" "}
                    <a className="link" href="https://bvote.com.vn/index.html">
                      BVOTE
                    </a>{" "}
                    VIỆT NAM
                  </p>
                  <li>
                    {/* <span>
                         <ImLocation2 className="icon"/> 
                         </span> */}
                    Tổ hợp văn phòng Sunsquare - Số 21 Lê Đức Thọ - Phường Mỹ
                    Đình 2 - Quận Nam Từ Liêm - Hà Nội.
                  </li>
                  <li>
                    <p>Bvote – Bình chọn nhanh nhạy- Tin cậy người dùng</p>
                  </li>
                  <li>
                    <span>
                      {" "}
                      {/* <MdLink className="icon" /> */}
                      <img src="images/icons/icon_link.svg" alt="" />
                    </span>
                    Website: https://bvote.com.vn/
                  </li>
                  <li>
                    <span>
                      {/* <FaPhone className="icon" /> */}
                      <img src="images/icons/icon_phone.svg" alt="" />
                    </span>
                    Hotline: +84 86 966 1866
                  </li>
                  <li>
                    <span>
                      {" "}
                      {/* <IoMdMail className="icon" /> */}
                      <img src="images/icons/icon_email.svg" alt="" />
                    </span>
                    Email: bvote@bvote.vn
                  </li>
                </ul>

                <Link className="about__link" to="/gioi-thieu">
                  <FormattedMessage id="index.continue" />
                  <img alt="arrow" src="/images/arrow-right.png" />
                </Link>
              </div>
              <div className="about-img">
                <div className="img">
                  <img src="images/intro.png" alt="" data-aos="zoom-out-left" />
                </div>
              </div>
            </div>
            {/* <div className="bs-col col-sm-12 md-50-15">
              <div class="about-img">
                <div class="img">
                  <img
                    src="images/introduce1.png"
                    alt=""
                    data-aos="zoom-out-left"
                  />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </Styles>
    );
  }
}

export default SectionAbout;

const Styles = styled.section`
  .module-about {
    padding: 6px 260px 40px 80px;
    position: relative;
    margin-right: 220px;
    z-index: 999;
    .module-header {
      margin-bottom: 25px;
      .title {
        padding-left: 0px;
        color: #2b2438;
        padding-top: 30px;
      }
    }
    .module-content {
      .about__link {
        //background: linear-gradient(110.38deg, #f738e0 0%, #42c6ff 100%);
        background: #ec7ca2;
        border-radius: 8px;
      }
      .info-website {
        .title {
          font-size: 16px;
          color: #2b2438;
          font-family: "Be Vietnam Pro";
          font-family: beVietnamPro-Bold;

          .link {
            color: #2b2438;
          }
        }
        li {
          font-style: unset;
          color: #2b2438;
          span {
            .icon {
              color: #807b88;
            }
          }
        }
        li:nth-child(3) {
          margin: 10px 0;
          color: #807b88;
        }
        li:nth-child(n + 4) {
          font-family: beVietnamPro-Bold;
        }
      }
    }
    .about-img {
      .img {
        padding-bottom: 135%;
        img {
          position: absolute;
          top: 26px;
          left: 260px;
          height: 100%;
          width: auto;
          object-fit: cover;
          border-top-right-radius: unset;
          border-bottom-left-radius: unset;
        }
      }
    }
    &::after {
      content: "";
      background-image: url("../images/background-missgrand2.png");
      position: absolute;
      top: 50px;
      left: -50%;
      width: 75%;
      height: 100%;
      transform: rotate(50deg);
      z-index: -1;
    }
  }
  @media only screen and (max-width: 1199px) and (min-width: 992px) {
    padding-top: 80px;
    .module-about {
      padding: 6px 232px 40px 60px;
      margin-right: 218px;
      .about-img {
        .img {
          img {
            top: 50px;
            left: 232px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    padding-bottom: 0px;
    .module-about {
      padding: 6px 20px 20px;
      margin-right: 0px;
      .module-header {
        .title {
          padding-left: 0px;
          padding-top: 10px;
          margin: 0;
        }
      }
    }
  }
  @media only screen and (max-width: 767px) and (min-width: 480px) {
    padding-top: 20px;
    padding-bottom: 0px;
    .item-row {
      padding: 0 10px;
      .module-about {
        padding: 0px 0px 20px;
        margin-right: 0px;
        .module-header {
          margin-bottom: 15px;
          .title {
            padding-left: 0px;
            padding-top: 10px;
            margin: 0;
          }
        }
        .module-content {
          .desc {
            .reward-content {
              .reward-part {
                p {
                  text-align: justify !important;
                }
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 479px) {
    padding-top: 30px;
    padding-bottom: 0px;
    .item-row {
      .module-about {
        padding: 0px 0px 20px;
        margin-right: 0px;
        .module-header {
          margin-bottom: 15px;
          .title {
            padding-left: 0px !important;
            padding-top: 10px !important;
            margin: 0;
          }
        }
        .module-content {
          .desc {
            .reward-content {
              .reward-part {
                p {
                  text-align: justify !important;
                }
              }
            }
          }
        }
      }
    }
  }
`;
