import React from "react";
import ReactHtmlParser from "react-html-parser";
// import { IoMdMail } from "react-icons/io";
// import { FaPhone } from "react-icons/fa";
// import { MdLink } from "react-icons/md";
import LayoutSubPage from "../../Layouts/LayoutSubPage";
import SectionBanner from "../Index/SectionBanner";
import { INTRO, INTRO_TITLE } from "../../../config/contentHtml";
import styled from "styled-components";
export default function Introduct() {
  return (
    <LayoutSubPage path="/gioi-thieu">
      <StylesCustom>
        {/* <section class=" banner-page">
          <div class="banner-content"></div>
        </section> */}
        <SectionBanner />
        <Styles className="section-rules">
          <div className="bs-container">
            <div className="bs-row">
              <div className="bs-col">
                <div
                  className="module module-rules aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-offset={-200}
                  data-aos-delay={300}
                >
                  <div
                    className="module-content aos-init aos-animate"
                    data-aos="fade-up"
                  >
                    <div className="body" style={{ paddingBottom: 30 }}>
                      <p className="body-title">{INTRO_TITLE}</p>

                      {ReactHtmlParser(INTRO)}
                      <ul className="info-website">
                        <p className="title">
                          CÔNG TY CỔ PHẦN{" "}
                          <a
                            className="link"
                            href="https://bvote.com.vn/index.html"
                          >
                            BVOTE
                          </a>{" "}
                          VIỆT NAM
                        </p>
                        <li>
                          {/* <span>
                         <ImLocation2 className="icon"/> 
                         </span> */}
                          Tổ hợp văn phòng Sunsquare - Số 21 Lê Đức Thọ - Phường
                          Mỹ Đình 2 - Quận Nam Từ Liêm - Hà Nội
                        </li>
                        <li>
                          Bvote - Bình chọn nhanh nhạy - Tin cậy người dùng
                        </li>
                        <li>
                          <span>
                            <img src="images/icons/icon_link.svg" alt="" />
                          </span>
                          Website: https://bvote.com.vn/
                        </li>
                        <li>
                          <span>
                            {" "}
                            <img src="images/icons/icon_email.svg" alt="" />
                          </span>
                          Email: bvote@bvote.vn
                        </li>
                        <li>
                          <span>
                            <img src="images/icons/icon_phone.svg" alt="" />
                          </span>
                          Hotline: +84 86 966 1866
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Styles>
        {/* <SectionBrand /> */}
      </StylesCustom>
    </LayoutSubPage>
  );
}

const Styles = styled.section`
  .module-rules {
    .module-content {
      box-shadow: none !important;
      padding: 0 !important;
      border: none !important;
      .body {
        .body-title {
          font-family: beVietnamPro-Bold;
          font-size: 18px;
          //background: linear-gradient(110.38deg, #f738e0 0%, #42c6ff 100%);
          background: #ec7ca2;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }
        .reward-content {
          .reward-part {
            .item {
              padding-bottom: 40px;
              border-bottom: 1px solid #eaebed;
              .item-title {
                font-family: beVietnamPro-SemiBold;
                font-size: 18px;
                background: #ec7ca2;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
              }
              .item-method {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 30px;
                .item-step {
                  padding: 20px;
                  border-radius: 8px;
                  p {
                    .item-text {
                      i {
                        font-style: inherit;
                        display: block;
                        padding-bottom: 10px;
                        font-family: beVietnamPro-Bold;
                        font-size: 18px;
                      }
                    }
                  }
                }
              }
              .item-structure {
                font-family: beVietnamPro-SemiBold;
                font-size: 18px;
                /* background: linear-gradient(
                  110.38deg,
                  #f738e0 0%,
                  #42c6ff 100%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text; */
                color: #ec7ca2;
              }
              .structure {
                margin-bottom: 3px;
              }
            }
          }
        }
        .info-website {
          margin-top: 40px;
          margin-left: 0px;
          .title {
            font-family: beVietnamPro-SemiBold;
            font-size: 16px;
            line-height: 150%;
            color: #2b2438;
            .link {
              color: #2b2438;
            }
          }
          li {
            font-style: inherit;
            font-size: 14px;
          }
          li:nth-child(3) {
            line-height: 150%;
            color: #807b88;
            padding: 5px 0 10px;
          }
          li:nth-child(n + 4) {
            font-family: beVietnamPro-Bold;
            font-size: 14px;
            line-height: 150%;
            color: #2b2438;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1199px) and (min-width: 992px) {
    .module-rules {
      .module-content {
        .body {
          padding: 10px 30px 106px !important;
        }
      }
    }
  }
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    .module-rules {
      .module-content {
        .body {
          padding: 10px 15px 106px !important;
          .reward-content {
            .reward-part {
              .item {
                .item-method {
                  .item-step {
                    padding: 15px;
                    p {
                      .item-text {
                        font-size: 15px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 767px) and (min-width: 480px) {
    .module-rules {
      .module-content {
        .body {
          padding: 10px 5px 70px !important;
          .reward-content {
            .reward-part {
              .item {
                padding-bottom: 25px;
                .item-method {
                  grid-template-columns: 1fr;
                  .item-step {
                    padding: 15px;
                  }
                }
              }
            }
          }
          .info-website {
            margin-top: 30px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 479px) {
    .module-rules {
      .module-content {
        .body {
          padding: 10px 0px 50px !important;
          .reward-content {
            .reward-part {
              .item {
                .item-method {
                  grid-template-columns: 1fr;
                  .item-step {
                    padding: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const StylesCustom = styled.div`
  @media only screen and (max-width: 576px) and (min-width: 480px) {
    .section-banner {
      margin-top: 82px;
    }
  }
`;
