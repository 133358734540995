import React, { useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ContestantsContext } from "../../../App";
import ContestantCard from "../../Partials/ContestantCard";
import styled from "styled-components";

export default function SectionEvent() {
  const { contestants } = useContext(ContestantsContext);

  const [empty, setEmpty] = useState(false);
  const [filter, setFilter] = useState("");

  const onInputChange = (e) => {
    const { value } = e.target;

    setEmpty(false);
    setFilter(value);
  };

  const filteredList = filter
    ? contestants.filter(
        (c) =>
          c.code.includes(filter) ||
          c.fullname.toLowerCase().includes(filter.toLowerCase())
      )
    : contestants;

  return (
    <Styles className="section-event">
      <div className="bs-container">
        <div className="bs-row">
          <div className="bs-col">
            <div className="module module-event">
              <div className="module-header" data-aos="fade-up">
                {/* <span className="id">03</span> */}
                <h2 className="title">
                  <FormattedMessage id="index.contestants" />
                </h2>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  position: "relative",
                  "margin-left": "auto",
                  width: "100%",
                  maxWidth: "250px",
                }}
              >
                <input
                  style={{
                    paddingRight: "0px",
                    textAlign: "center",
                  }}
                  value={filter}
                  onChange={onInputChange}
                  placeholder="Tìm kiếm theo tên / số báo danh"
                />
                <i
                  className="fas fa-search"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "0%",
                    transform: "translate(-90%,-50%) rotateY(180deg)",
                    right: "10px",
                    marginLeft: "-2px",
                    opacity: "0.5",
                  }}
                ></i>
              </div>
              <div className="module-content">
                <div className="tab-content">
                  <div className="tab-item active">
                    <div className="bs-row row-xs-15">
                      {!empty && filteredList.length ? (
                        filteredList.map((el) => {
                          return (
                            <ContestantCard
                              key={el._id}
                              code={el.code}
                              missName={el.fullname}
                              table={el.info?.table}
                              votePoints={el.totalPoint}
                              missId={el.contestantId}
                              favoritePoint={0}
                            />
                          );
                        })
                      ) : (
                        <div
                          className="bs-col lg-25-15 md-33-15 xs-50-15"
                          style={{
                            margin: "auto",
                            textAlign: "center",
                          }}
                        >
                          Không có
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
}

const Styles = styled.section`
  background-repeat: round;
  .module-event {
    position: relative;
    &::before {
      content: "";
      background-image: url("../../images/befo_event.png");
      width: 128px;
      height: 130px;
      position: absolute;
      top: 15px;
      right: -130px;
      background-repeat: no-repeat;
      z-index: -1;
    }
    &::after {
      content: "";
      background-image: url("../../images/befo2_event.png");
      width: 121px;
      height: 130px;
      position: absolute;
      top: 48%;
      left: -155px;
      background-repeat: no-repeat;
      z-index: -1;
    }
    .module-header {
      .title {
        margin: 0px;
        font-size: 52px;
        color: #2b2438;
        padding-left: 0px;
        padding-bottom: 0px;
      }
    }
    .module-content {
      .item {
        border-radius: 16px;
        box-shadow: 0 0 26px rgba(0, 0, 0, 0.05);
        filter: drop-shadow(4px 4px 24px rgba(166, 98, 207, 0.16));
        .item-content {
          .img {
            z-index: 10;
            .like {
              padding: 4px;
              position: absolute;
              background: rgba(0, 0, 0, 0.2);
              display: flex;
              align-items: center;
              justify-content: center;
              right: 7px;
              top: 10px;
              gap: 10px;
              color: #fff;
              border-radius: 4px;
              img {
                width: 100%;
                position: unset;
                cursor: pointer;
                border-top-left-radius: 16px;
                border-top-right-radius: 16px;
              }
            }
            img {
              border-top-left-radius: 16px;
              border-top-right-radius: 16px;
            }
            .sbd {
              background: #ec7ca2 !important;
              border-radius: 4px 4px 4px 0px !important;
              &:after {
                border-top: 4px solid #ec7ca2 !important;
                border-right: 4px solid #ec7ca2 !important;
              }
            }
          }
          .text {
            border-bottom-right-radius: 16px;
            border-bottom-left-radius: 16px;
            position: relative;
            z-index: 2;
            margin-top: -4px;

            .name {
              font-family: beVietnamPro-SemiBold;
              font-size: 16px;
              line-height: 150%;
              color: #373143;
              text-align: center;
            }
            .point {
              text-align: center;
              span {
                display: block;
                font-family: beVietnamPro-SemiBold !important;
                font-size: 24px !important;
                line-height: 150% !important;
                color: #ec7ca2 !important;
              }
            }
            .link {
              background: rgba(0, 0, 0, 0.1) !important;
              border-radius: 8px !important;
              font-size: 16px;
              color: #2b2438 !important;
              &:hover {
                background: #ec7ca2 !important;
                color: #fff !important;
                box-shadow: none !important;
              }
            }
          }
          &:hover {
            box-shadow: none;
            border-radius: 8px;
            .text {
              background: #ec7ca2;
              z-index: 9;
              &:after {
                content: "";
                display: inline-block;
                width: calc(100% - 6px);
                height: calc(100% - 5px);
                background: #fff;
                border-bottom-left-radius: 16px;
                border-bottom-right-radius: 16px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: -1;
              }
            }
          }
        }
      }
    }
  }

  .control__btn {
    background: rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px !important;
    font-size: 16px;
    color: #2b2438 !important;
    &:hover {
      background: linear-gradient(
        110.38deg,
        #f738e0 0%,
        #42c6ff 100%
      ) !important;
      color: #fff !important;
      box-shadow: none !important;
    }
  }

  .tab-active {
    background: rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px !important;
    font-size: 16px;
    background: linear-gradient(110.38deg, #f738e0 0%, #42c6ff 100%) !important;
    color: #fff !important;
    box-shadow: none !important;
  }

  @media only screen and (max-width: 1199px) and (min-width: 992px) {
    .module-event {
      &::before {
        right: -90px;
      }
      &::after {
        left: -95px;
      }
      .module-header {
        margin-top: 35px;
      }
    }
  }
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    background-repeat: no-repeat;
    .module-event {
      &::before {
        right: -90px;
      }
      &::after {
        left: -95px;
      }
      .module-header {
        margin-top: 15px;
      }
    }
  }
  @media only screen and (max-width: 767px) and (min-width: 480px) {
    background-repeat: no-repeat;
    .module-event {
      &::before {
        right: -35px;
      }
      &::after {
        left: -55px;
      }
      .module-header {
        margin-top: 5px;
      }
    }
  }
  @media only screen and (max-width: 479px) {
    background-repeat: no-repeat;
    .module-event {
      .module-header {
        margin-top: 30px;
      }
    }
  }
`;
