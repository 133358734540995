import React from "react";
import { Link } from "react-router-dom";
import { getAvatarUrl } from "../../../store";
import { AiFillHeart } from "react-icons/ai";
export default function RankItem({ rank, info, isTeam, point, isVoteTab }) {
  //   console.log("info", info);
  return (
    <Link to={`/thi-sinh/${info?.contestantId}`}>
      <div className="item-content">
        <div className="item-avatar">
          <div className="image">
            <div className={`img-frame ${isTeam ? "img-team" : ""}`}>
              <img alt="" src={getAvatarUrl(info?.code)} />
            </div>
          </div>
        </div>
        <div className="item-info">
          <p className="point">
            <span>Điểm bình chọn:</span> {point}{" "}
            {/*{!isVoteTab && <AiFillHeart className="heart-icon" />}*/}
          </p>
          <p className="name">{info.fullname}</p>
          <p className="sbd">sbd:{info.code}</p>
          {/* <p className="sbd">sbd:253  </p> */}
        </div>
        {rank === 1 ? (
          <div className="icon-ratings">
            {/*<img src="/images/icons/icon_top1.svg" alt="" className="icon" />*/}
            <span className="text">NO.{rank}</span>
          </div>
        ) : rank === 2 ? (
          <div className="icon-ratings">
            {/*<img src="/images/icons/icon_top2.svg" alt="" className="icon" />*/}
            <span className="text">NO.{rank}</span>
          </div>
        ) : rank === 3 ? (
          <div className="icon-ratings">
            {/*<img src="/images/icons/icon_top3.svg" alt="" className="icon" />*/}
            <span className="text">NO.{rank}</span>
          </div>
        ) : (
          ""
        )}
      </div>

      {rank === 1 ? (
        <div className="ratings">
          {/*<img src="/images/top1.png" alt="" className="icon" />*/}
          {/*<span className="text">No.{rank}</span>*/}
        </div>
      ) : rank === 2 ? (
        <div className="ratings">
          {/*<img alt="img" src="/images/top2.png" className="icon" />*/}
          {/*<span className="text">No.{rank}</span>*/}
        </div>
      ) : rank === 3 ? (
        <div className="ratings">
          {/*<img alt="img" src="/images/top3.png" className="icon" />*/}
          {/*<span className="text">No.{rank}</span> */}
        </div>
      ) : (
        <div className="ratings">
          <div className="wrap-rating">
            <span className="text">No.{rank}</span>
          </div>
        </div>
      )}
    </Link>
  );
}
